import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router';
import Papaparse from 'papaparse';
import _ from 'lodash';
import {
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import { ColoredPill, insightStyles } from './InsightStyle';

import csvImg from '../../images/connection/conn_csv_full.svg';
import deleteImg from '../../images/delete-icon.svg';

import { ReactComponent as UploadImg } from '../../images/audience/upload-icon.svg';
import AlertUtil from '../../utils/AlertUtil';
import { StyledTableRow } from '../common/tableStyles';

import ConfirmModal from './ConfirmModal';
import { getEmotionsFromTexts, uploadCSVDataForInsights } from '../../services/insightService';

type HeaderItem = {
  user_id: string;
  content: string;
};

interface IInsightRow {
  uniq_id: number;
  text: string;
  emotion: string;
  sentiment: string;
  intent: string;
  content: string;
  user_id: string;
}

export default function CohortDetailsComponent() {
  const classes = insightStyles();
  const history = useHistory();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [csvFile, setCSVFile] = useState<File | null>();
  const [csvRows, setCSVRows] = useState<Array<any>>([]);
  const [fileName, setFileName] = useState('');
  const [isInsightLoading, setInsightLoading] = useState(false);

  const [openConfirmModal, toggleConfirmModal] = useState(false);

  const handleFileChange = async event => {
    const csvfile = event.target.files[0];
    if (csvfile) {
      setCSVFile(csvfile);
      Papaparse.parse(csvfile, {
        worker: true,
        skipEmptyLines: true,
        header: true,

        complete: async ({ data }: any) => {
          if (data && data.length > 0) {
            setFileName(csvfile.name);

            const previewItem: any = data[0];
            const headers: Array<HeaderItem> = [];
            for (const i in previewItem) {
              headers.push({ user_id: i, content: 'string' });
            }
            const user_id_obj = headers.find(o => o.user_id.trim() === 'user_id');
            if (user_id_obj) {
              setCSVRows(data);
              const insights: Array<IInsightRow> = [];
              for (const i in data) {
                if (data[i] && data[i].content) {
                  insights.push({
                    uniq_id: +i,
                    text: data[i].content,
                    content: data[i].content,
                    user_id: data[i].user_id,
                    emotion: '',
                    sentiment: '',
                    intent: '',
                  });
                }
              }
              setInsightLoading(true);
              const emotions = await getEmotionsFromTexts({ insights });
              if (emotions && emotions.data) {
                for (const i in insights) {
                  const item = emotions.data.find(d => d.uniq_id === insights[i].uniq_id);
                  if (item) {
                    insights[i].sentiment = item.sentiment;
                    insights[i].emotion = item.emotion;
                    insights[i].intent = item.intent;
                  }
                }
                setCSVRows(insights);
              }
              setInsightLoading(false);
            } else {
              setCSVFile(null);
              AlertUtil.fire({
                icon: 'error',
                title: 'user_id is not present',
              });
            }
          }
        },
      });
    }
  };

  const showOpenFileDialog = () => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleMerge = async () => {
    if (csvRows.length === 0) {
      return;
    }

    const results = await uploadCSVDataForInsights({
      insights: csvRows,
      source_url: fileName,
    });

    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Uploaded successfully',
      });

      setTimeout(() => {
        history.push('/respository');
      }, 1000);
    }
  };

  return (
    <Box>
      {<ConfirmModal open={openConfirmModal} toggleModal={toggleConfirmModal} handleMerge={handleMerge} />}
      <Box display={'flex'} alignItems="center" justifyContent="space-between">
        <img
          src={deleteImg}
          alt=""
          style={{ height: 28, cursor: 'pointer' }}
          onClick={() => {
            history.goBack();
          }}
        />
        <img src={csvImg} style={{ height: 32, marginInline: '10px' }} />
        <TextField
          variant="outlined"
          value={fileName}
          autoComplete="off"
          placeholder="Untitled file"
          onChange={e => {
            setFileName(e.target.value);
          }}
          style={{ flexGrow: 1 }}
        />

        <Box display={'flex'} alignItems={'center'} ml={2}>
          {/* <Button variant="contained" className={classes.generateButton}>
            ✨ Generate insights
          </Button> */}

          <Button
            variant="outlined"
            style={{ lineHeight: 2, marginLeft: 10 }}
            onClick={() => {
              toggleConfirmModal(true);
            }}
          >
            Merge to respository
          </Button>
        </Box>
      </Box>
      {csvRows.length === 0 ? (
        <div>
          <Grid container lg={12} className={classes.newCsvContainer}>
            <Grid item lg={4} py={5.2} px={3.5} className={classes.newCsvBox}>
              <Typography variant={'h2'} fontSize={'22px'}>
                Upload Your CSV
              </Typography>

              <Typography variant={'subtitle1'} mt={3.5}>
                Your CSV must have two columns: 'user_id' and 'content'. Preview your data before merging
              </Typography>

              <Typography variant={'subtitle2'} mt={3.5}>
                *max file size = 10MB
              </Typography>
              <div>
                <input
                  type="file"
                  id="file"
                  ref={inputFileRef}
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Button variant="contained" className={classes.uploadCsvButton} onClick={() => showOpenFileDialog()}>
                  <Box display={'flex'} alignItems={'center'}>
                    <UploadImg />
                    <Typography pl={0.6} variant={'subtitle1'} fontWeight={700}>
                      {!csvFile ? 'Preview CSV' : 'CSV Uploaded'}
                    </Typography>
                  </Box>
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <TableContainer>
          <Table className={classes.previewTable}>
            <TableHead>
              <TableRow>
                <TableCell scope="row">
                  <Typography style={{ paddingLeft: 10, fontWeight: 'bold' }} noWrap>
                    user_id
                  </Typography>
                </TableCell>
                <TableCell scope="row">
                  <Typography style={{ paddingLeft: 10, fontWeight: 'bold' }} noWrap>
                    content
                  </Typography>
                </TableCell>
                <TableCell scope="row">
                  <Box display={'flex'}>
                    <Typography style={{ paddingLeft: 10, fontWeight: 'bold', marginRight: 5 }} noWrap>
                      emotion
                    </Typography>
                    {isInsightLoading && (
                      <CircularProgress className={classes.loading} size={16} style={{ marginTop: 5 }} />
                    )}
                  </Box>
                </TableCell>
                <TableCell scope="row">
                  <Box display={'flex'}>
                    <Typography style={{ paddingLeft: 10, fontWeight: 'bold', marginRight: 5 }} noWrap>
                      sentiment
                    </Typography>
                    {isInsightLoading && (
                      <CircularProgress className={classes.loading} size={16} style={{ marginTop: 5 }} />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {csvRows.map((item, idx) => (
                <StyledTableRow key={idx}>
                  <TableCell scope="row" width={'15%'}>
                    <Typography style={{ paddingLeft: 10 }}>{item.user_id}</Typography>
                  </TableCell>
                  <TableCell scope="row" width={'55%'}>
                    <Typography style={{ paddingLeft: 10 }} flexWrap={'wrap'}>
                      {item.content}
                    </Typography>
                  </TableCell>
                  <TableCell width={'12%'}>
                    <Box>{item.emotion && <ColoredPill sentiment={item.emotion} add_strong={false} />}</Box>
                  </TableCell>
                  <TableCell width={'12%'}>
                    <Box display={'flex'} flexWrap={'wrap'}>
                      {item.sentiment && <ColoredPill sentiment={item.sentiment} add_strong={false} />}
                    </Box>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
